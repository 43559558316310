article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
    display: block;
}
audio,
canvas,
video {
    display: inline-block;
}
audio:not([controls]) {
    display: none;
    height: 0;
}
[hidden] {
    display: none;
}
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}
a:active,
a:hover {
    outline: 0;
}
address,
caption,
cite,
code,
dfn,
em,
strong,
th,
var {
    font-style: normal;
}
em {
    font-weight: inherit;
}
code,
kbd,
pre,
samp {
    font-family: monospace, serif;
    font-size: 1em;
}
pre {
    white-space: pre-wrap;
}
q {
    quotes: '\201C''\201D''\2018''\2019';
}
small {
    font-size: 80%;
}
img {
    border: 0;
}
img,
object,
embed {
    vertical-align: top;
}
button,
input,
select,
textarea {
    font-size: 100%;
    font-family: inherit;
    border: 0;
}
textarea {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    resize: none;
    padding: 0;
    border: 0;
    outline: none;
    background: transparent;
}
select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &:focus {
        outline: none;
    }
}
input {
    &:focus {
        outline: none;
    }
}
button,
input {
    line-height: normal;
    background-color: transparent;
}
button,
select {
    text-transform: none;
}
button,
html input[type='button'],
input[type='reset'],
input[type='submit'] {
    cursor: pointer;
    -webkit-appearance: button;
}
button[disabled],
html input[disabled] {
    cursor: default;
}
input[type='checkbox'],
input[type='radio'] {
    box-sizing: border-box;
    padding: 0;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
    border: 0;
    padding: 0;
}
textarea {
    overflow: auto;
    vertical-align: top;
}
button {
    padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-size: 100%;
    font-weight: normal;
}
p {
    margin: 0;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
caption,
th {
    text-align: left;
}
ul {
    margin: 0;
    padding: 0;
}
li {
    list-style-type: none;
}
i {
    font-style: normal;
}
