@import '../utils/variables';

.is-hover {
    .js-hover__img {
        transform: scale(1);
        transition: transform 0.6s $easeInOut;
    }
    .js-hover__color__point {
        color: $point;
        transition: color 0.15s $easeInOut;
    }
    .js-hover__opacity {
        opacity: 1;
        transition: opacity 0.15s;
        & + .js-hover__arrow__z {
            opacity: 1;
            transition: opacity 0.15s;
        }
    }
    .js-hover__arrow__x-svg {
        &:nth-child(1) {
            transform: translateX(150%);
            transition: transform 0.6s $easeInOut;
        }
        &:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translateX(0%);
            transition: transform 0.6s $easeInOut;
        }
    }
    .js-hover__arrow__z-svg {
        &:nth-child(1) {
            transform: translate(150%, -150%);
            transition: transform 0.6s $easeInOut;
        }
        &:nth-child(2) {
            position: absolute;
            top: 0;
            left: 0;
            transform: translate(0%);
            transition: transform 0.6s $easeInOut;
        }
    }
    .accordionItemTitle {
        color: $point;
        transition: color 0.15s $easeInOut;
    }
    .accordionItemSvg {
        path {
            stroke: $point;
            transition: stroke 0.15s $easeInOut;
        }
    }
    .accordionTargetText {
        color: $point;
        transition: color 0.15s $easeInOut;
        &::before {
            border-color: $point;
            transition: border-color 0.15s $easeInOut;
        }
    }
    .accordionTargetInput[type='radio']:checked + .accordionTargetText::before {
        border-color: $point;
        background-color: $point;
        transition: border-color 0.2s $easeInOut,
            background-color 0.2s $easeInOut;
    }
    .accordionTargetInput[type='checkbox']:checked
        + .accordionTargetText:before {
        background-color: $point;
        border-color: $point;
        transition: background-color 0.2s $easeInOut,
            border-color 0.2s $easeInOut;
    }
    .findHeadSearchInput {
        border-color: $key;
        transition: border-color 0.15s $easeInOut;
    }
    .footerNavSnsSvg {
        path {
            fill: $point;
            transition: fill 0.15s $easeInOut;
        }
    }
}
.js-hover__opacity {
    opacity: 0.5;
    transition: opacity 0.9s;
    & + .js-hover__arrow__z {
        opacity: 0.5;
        transition: opacity 0.9s;
    }
}
.js-hover__arrow__x {
    position: relative;
}
.js-hover__arrow__x-svg {
    &:nth-child(1) {
        transform: translateX(0);
    }
    &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateX(-150%);
    }
}
.js-hover__arrow__z {
    position: relative;
}
.js-hover__arrow__z-svg {
    &:nth-child(1) {
        transform: translate(0);
    }
    &:nth-child(2) {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-150%, 150%);
    }
}
.accordionItemTitle {
    transition: color 0.9s $easeInOut;
}
.accordionItemSvg {
    path {
        stroke: $key;
        transition: stroke 0.9s $easeInOut;
    }
}
.accordionItemSvgWhite {
    path {
        stroke: $white;
    }
}
.accordionTargetText {
    transition: color 0.9s $easeInOut;
    &::before {
        transition: border-color 0.9s $easeInOut;
    }
}
.accordionTargetInput[type='radio']:checked + .accordionTargetText::before {
    border-color: $point;
    background-color: $point;
    transition: border-color 0.2s $easeInOut, background-color 0.2s $easeInOut;
}
.accordionTargetInput[type='checkbox']:checked + .accordionTargetText::before {
    border-color: $point;
    background-color: $point;
    transition: border-color 0.2s $easeInOut, background-color 0.2s $easeInOut;
}
.findHeadSearchInput {
    border-color: $gray;
    transition: border-color 0.7s $easeInOut;
}
.footerNavSnsSvg {
    path {
        fill: $white;
        transition: fill 0.9s $easeInOut;
    }
}
.is-hover.js-hover__btn__key {
    background-color: $key;
    transition: background-color 0.15s $easeInOut;
    // def
    .orderTextParentInnerWord {
        max-width: 19.81px;
        transition: max-width 0.3s $easeInOut;
    }
    .orderTextParentInnerMark {
        max-width: 3.97px;
        transition: max-width 0.3s $easeInOut;
    }
    .orderTextWord {
        &:nth-of-type(1) {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.15s $easeInOut, opacity 0.2s;
        }
        &:nth-of-type(2) {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.15s $easeInOut, opacity 0.2s;
        }
    }
    // lg
    .orderTextParentInnerWordLg {
        max-width: 28.34px;
        transition: max-width 0.3s $easeInOut;
    }
    .orderTextParentInnerMarkLg {
        max-width: 6.03px;
        transition: max-width 0.3s $easeInOut;
    }
}
.is-hover.js-hover__btn__white {
    background-color: $white;
    transition: background-color 0.15s $easeInOut;
    .js-hover__btn__white-text {
        color: $key;
        transition: color 0.15s $easeInOut;
    }
}
.is-hover.js-hover__btn__sp-white {
    background-color: $key;
    transition: background-color 0.15s $easeInOut;
    // def
    .orderTextParentInnerWord {
        max-width: 19.81px;
        transition: max-width 0.3s $easeInOut;
    }
    .orderTextParentInnerMark {
        max-width: 3.97px;
        transition: max-width 0.3s $easeInOut;
    }
    .orderTextWord {
        &:nth-of-type(1) {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.15s $easeInOut, opacity 0.2s;
        }
        &:nth-of-type(2) {
            transform: translateX(0);
            opacity: 1;
            transition: transform 0.15s $easeInOut, opacity 0.2s;
        }
    }
}
.orderTextParentInnerWord {
    max-width: 0;
    transition: max-width 0.6s $easeInOut;
}
.orderTextParentInnerMark {
    max-width: 0;
    transition: max-width 0.6s $easeInOut;
}
.orderTextWord {
    &:nth-of-type(1) {
        transform: translateX(-100%);
        opacity: 0;
        transition: transform 0.8s $easeInOut, opacity 1.5s;
    }
    &:nth-of-type(2) {
        transform: translateX(-200%);
        opacity: 0;
        transition: transform 0.8s $easeInOut, opacity 1.5s;
    }
}
// lg
.orderTextParentInnerWordLg {
    max-width: 0;
    transition: max-width 0.6s $easeInOut;
}
.orderTextParentInnerMarkLg {
    max-width: 0;
    transition: max-width 0.6s $easeInOut;
}

.is-hover.js-hover__btn__key {
    background-color: $key;
    transition: background-color 0.15s $easeInOut;
}
.js-hover__btn__key {
    transition: background-color 0.9s $easeInOut;
}

.is-hover.js-hover__btn__point {
    background-color: $point;
    transition: background-color 0.15s $easeInOut;
}
.js-hover__btn__point {
    transition: background-color 0.9s $easeInOut;
}
.is-hover.js-hover__color__point {
    color: $point;
    transition: color 0.15s $easeInOut;
    &::before {
        background-color: $point;
        transition: background-color 0.15s $easeInOut;
    }
}
.js-hover__color__point {
    transition: color 0.9s $easeInOut;
    &::before {
        transition: background-color 0.9s $easeInOut;
    }
}
.is-hover.breadcrumbLink {
    color: $point;
    transition: color 0.15s $easeInOut;
    &::after {
        background-color: $point;
        transition: background-color 0.15s $easeInOut;
    }
}
.breadcrumbLink {
    transition: color 0.9s $easeInOut;
    &::after {
        transition: background-color 0.9s $easeInOut;
    }
}
.js-hover__img {
    transform: scale(1.05);
    transition: transform 0.9s $easeInOut;
}
// .js-hover__btn {
//     background-color: $key_sub;
//     transition: background-color 0.9s $easeInOut;
//     &:hover {
//         background-color: $key;
//         transition: background-color 0.15s $easeInOut;
//     }
// }
.js-hover__btn__sp-white {
    background-color: $key_sub;
    transition: background-color 0.9s $easeInOut;
}
.js-hover__btn__key {
    transition: background-color 0.9s $easeInOut;
}
.js-hover__btn__white {
    background-color: unset;
    transition: background-color 0.9s $easeInOut;
    .js-hover__btn__white-text {
        color: white;
        transition: color 0.9s $easeInOut;
    }
}

.is-hover.js-hover__btn__gray {
    background-color: $key;
    transition: background-color 0.15s $easeInOut;
    .js-hover__btn__gray-text {
        color: $white;
        transition: color 0.15s $easeInOut;
    }
}
.js-hover__btn__gray {
    background-color: $gray;
    transition: background-color 0.9s $easeInOut;
    .js-hover__btn__gray-text {
        color: $key_sub;
        transition: color 0.9s $easeInOut;
    }
}
.js-hover__color {
    color: $key;
    transition: color 0.9s $easeInOut;
    &:hover {
        color: $point;
        transition: color 0.15s $easeInOut;
    }
}
.headerNavLink {
    color: $key;
}

.is-current.is-hover {
    .js-hover__color__point {
        color: $key;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .is-hover.js-hover__btn__key {
        // def
        .orderTextParentInnerWord {
            max-width: 2rem;
        }
        // lg
        .orderTextParentInnerWordLg {
            max-width: 2.3rem;
        }
        .orderTextParentInnerMarkLg {
            max-width: 6.03px;
        }
    }
    .is-hover.js-hover__btn__sp-white {
        // def
        .orderTextParentInnerWord {
            max-width: 2rem;
        }
    }
    .js-hover__btn__sp-white {
        background-color: $white;
        transition: background-color 0.9s $easeInOut;
    }
    .is-hover.js-hover__btn__sp-white {
        background-color: unset;
    }
    .headerNavLink {
        color: $white;
    }
}
