@import 'variables';

.headerNavItemLink {
    display: inline-block;
    padding: 2.75rem 1.5rem;
    color: $key;
    font-size: 1.3rem;
    font-family: $gilroyExtra;
    font-weight: $bold;
    letter-spacing: 0.125rem;
}

//
//
//

@media screen and (max-width: $sp) {
    .headerNavItemLink {
        display: block;
        padding: 0.8rem 0;
        color: $white;
        font-size: 2.4rem;
        letter-spacing: 0.235rem;
    }
    .headerNavItemChildLink {
        display: block;
        padding: 0.95rem 0;
        color: $white;
        font-size: 1.3rem;
        font-family: $gilroyExtra;
        letter-spacing: 0.13rem;
    }
}
