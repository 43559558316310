@import '../../utils/variables';

.alertKv {
    .swiper-wrapper {
        transition-timing-function: $easeInOut;
    }
    .swiper-button-next {
        top: calc(50% + 0.15rem);
        right: -0.5rem;
        width: 4rem;
        height: 4rem;
        &::before {
            content: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M1 6L11.0018 6" stroke="%23F9F8F3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M6.00085 11L11.0009 6.00082L6.00085 1" stroke="%23F9F8F3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>');
            width: 1.2rem;
            height: 1.2rem;
            z-index: 11;
        }
        &::after {
            content: none;
        }
    }
    .swiper-button-prev {
        top: calc(50% + 0.15rem);
        left: -0.5rem;
        width: 4rem;
        height: 4rem;
        &::before {
            content: url('data:image/svg+xml;utf-8,<svg viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M11.0018 6L1 6" stroke="%23F9F8F3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/><path d="M6.00091 1L1.00092 5.99918L6.00092 11" stroke="%23F9F8F3" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/></svg>');
            width: 1.2rem;
            height: 1.2rem;
            z-index: 11;
        }
        &::after {
            content: none;
        }
    }
}
.topKv {
    .swiper-wrapper {
        transition-timing-function: $easeInOut;
    }
    .swiper-horizontal {
        .swiper-pagination {
            bottom: 4.75rem;
            right: 9.45rem;
            left: auto;
            width: fit-content;
        }
        .swiper-pagination-bullets {
            .swiper-pagination-bullet {
                margin: 0 0.6rem;
                background-color: $white;
            }
        }
    }
}
.eatBeverages {
    .swiper {
        padding: 0 0 0 2rem;
    }
}
.eatEncounter {
    .swiper {
        padding: 0 0 0 2rem;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    .alertKv {
        .swiper-button-next {
            display: none;
            &::before {
                content: none;
            }
        }
        .swiper-button-prev {
            display: none;
            &::before {
                content: none;
            }
        }
        .swiperLink {
            display: block;
            width: fit-content;
            margin: 0 auto;
        }
    }
    .topKv {
        .swiper-horizontal {
            .swiper-pagination {
                bottom: auto;
                top: 34.25rem;
                left: 0;
                width: 100%;
            }
            .swiper-pagination-bullets {
                .swiper-pagination-bullet {
                    margin: 0 0.6rem;
                    background-color: $white;
                }
            }
        }
    }
    .eatBeverages {
        .swiper {
            padding: 0 0 0 0.9rem;
        }
    }
    .eatEncounter {
        .swiper {
            padding: 0 0 0 0.9rem;
        }
    }
}
