@import 'variables';

.u-none__pc {
    display: none;
}
.u-none__sp {
    display: block;
}

//
//
//

@media screen and (max-width: $sp) {
    .u-none__pc {
        display: block;
    }
    .u-none__sp {
        display: none;
    }
}
