.is-current {
    .eatBodyCategoriesLink {
        color: $point;
    }
    .paginationLink {
        pointer-events: none;
        &::before {
            opacity: 1;
        }
    }
}
.is-current__location {
    .locationMainFindBodyItemSection {
        border: 2px solid $key;
    }
    .js-current__accordion {
        // max-height: 32.4rem;
        max-height: 42rem;
        opacity: 1;
        visibility: visible;
        pointer-events: all;
        transition: max-height 1.55s $easeOut, opacity 1.35s, visibility 1.35s;
    }
}
.is-current__marker {
    .locationMapMarkerIcon {
        path {
            fill: $point;
        }
    }
}
.is-current__prevent {
    .paginationLink {
        pointer-events: none;
    }
}
.eatBodyCategoriesLink {
    color: $key;
}
.paginationLink {
    pointer-events: all;
    &::before {
        opacity: 0;
    }
}
.locationMainFindBodyItemSection {
    border: 2px solid $white;
    webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    &:focus {
        outline: none;
    }
}
.js-current__accordion {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: opacity 0.1s, visibility 0.1s;
}
.locationMapMarkerIcon {
    path {
        fill: $key_sub;
    }
}
