@import '../utils/variables';

.is-copied {
    // .promoCodeSpeech {
    //     background-color: $white;
    //     transition: background-color 0.3s $easeInOut;
    // }
    // .promoCodeSpeechText {
    //     color: $key;
    //     transition: color 0.3s $easeInOut;
    // }
    .promoCodeCopied {
        opacity: 1;
        transition: opacity 0.5s;
    }
}
// .promoCodeSpeech {
//     background-color: $key;
//     transition: background-color 0.5s $easeInOut;
// }
// .promoCodeSpeechText {
//     color: $white;
//     transition: color 0.5s $easeInOut;
// }
.promoCodeCopied {
    opacity: 0;
    transition: opacity 0.8s;
}
