@import 'variables';
@import '../font/font.scss';
@import '../font/MyFontsWebfontsKit.scss';
@import '../utils/reset.scss';
@import '../utils/none.scss';
@import '../utils/header.scss';
@import '../components/ui/swiper.scss';
@import '../state/active.scss';
@import '../state/current.scss';
@import '../state/hover.scss';
@import '../state/load.scss';
@import '../state/show.scss';
@import '../state/copied.scss';

html,
body {
    padding: 0;
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
    background-color: #f9f8f3;
    color: $key;
}
html {
    font-size: 0.625vw;
}
::-webkit-scrollbar {
    width: 10px;
}
::-webkit-scrollbar-track {
    background: #f9f8f3;
    border: none;
}
::-webkit-scrollbar-thumb {
    background: #0c3b2c;
    border-radius: 5px 3.5px 3.5px 5px;
    box-shadow: none;
}
::selection {
    color: #f9f8f3;
    background: $key;
}
.deviceMobile {
    .u-prevent__pc {
        pointer-events: all;
    }
}
.deviceOther {
    .u-prevent__pc {
        pointer-events: none;
    }
}
body {
    width: 100vw;
    overflow-x: hidden;
}
a {
    color: inherit;
    text-decoration: none;
}
input {
    &:focus {
        &::placeholder {
            color: transparent;
            transition: color 0.2s $easeInOut;
        }
    }
    &::placeholder {
        transition: color 0.7s $easeInOut;
    }
}
* {
    box-sizing: border-box;
}
.breadcrumbLink {
    transition: color 0.9s $easeInOut;
}
.white {
    overflow-x: hidden !important;
    .breadcrumbLink {
        color: $white;
    }
}
button,
a,
select,
input,
label {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

//
//
//

@media screen and (min-width: $pc) {
    html {
        font-size: 62.5%;
    }
}

//
//
//

@media screen and (max-width: $sp) {
    html {
        font-size: 2.6667vw;
    }
}
