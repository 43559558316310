@import '../utils/variables';

.is-show.paginationArrow {
    visibility: visible;
}
.paginationArrow {
    visibility: hidden;
}

//
//
//

@media screen and (max-width: $sp) {
    .is-show {
        .headerNav {
            opacity: 1;
            visibility: visible;
            pointer-events: all;
            transition: opacity 0.3s, visibility 0.3s;
        }
        .headerButtonBorder {
            background-color: $white;
            transition: background-color 0.3s $easeInOut;
            &:nth-child(1) {
                top: 0;
                transform: rotate(45deg);
                transition: transform 0.3s $easeInOut;
            }
            &:nth-child(2) {
                top: 0;
                transform: rotate(-45deg);
                transition: transform 0.3s $easeInOut;
            }
        }
        .eatFiltersBody {
            transform: translateX(0%);
            pointer-events: all;
            transition: transform 0.3s $easeInOut;
        }
    }
    .headerNav {
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
        transition: opacity 0.7s, visibility 0.7s;
    }
    .headerButtonBorder {
        background-color: $key;
        transition: background-color 0.7s $easeInOut;
        &:nth-child(1) {
            top: -0.5rem;
            transform: rotate(0);
            transition: transform 0.7s $easeInOut;
        }
        &:nth-child(2) {
            top: 0.2rem;
            transform: rotate(0);
            transition: transform 0.7s $easeInOut;
        }
    }
    .eatFiltersBody {
        transform: translateX(-100%);
        pointer-events: none;
        transition: transform 0.7s $easeInOut;
    }
}
