@import '../utils/variables';

.is-active {
    .accordionItem {
    }
    .accordionItemSvg {
        &:nth-child(1) {
            transform: rotate(0);
            transition: transform 0.3s $easeInOut;
        }
    }
    .accordionTarget {
        opacity: 1;
        visibility: visible;
        transition: opacity 0.5s, visibility 0.5s, max-height 0.3s $easeInOut;
    }
}
.footerNavItem {
    &:nth-child(3) {
        .main {
            .linkText {
                font-family: $gilroyExtra;
            }
        }
    }
    &:nth-child(4) {
        .main {
            .linkText {
                font-family: $gilroyExtra;
            }
        }
    }
}
.main {
    .js-hover__opacity + .js-hover__arrow__z {
        opacity: 1;
    }
    .linkText {
        font-family: $gilroyExtra;
        letter-spacing: 0.125rem;
        opacity: 1;
    }
}
.accordionSpItem {
    .accordionTarget {
        opacity: 1;
        visibility: visible;
    }
}
.accordionItemSvg {
    &:nth-child(1) {
        transform: rotate(90deg);
        transition: transform 0.7s $easeInOut;
    }
}
.accordionTarget {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s, visibility 0.5s, max-height 0.7s $easeInOut;
}

.is-active.headerNavLink {
    color: $point;
}

//
//
//

@media screen and (max-width: $sp) {
    .main {
        .js-hover__opacity + .js-hover__arrow__z {
            opacity: 0.5;
        }
        .linkText {
            font-family: $noto;
            opacity: 0.5;
        }
    }
    .footerNavItem {
        .main {
            .linkText {
                font-family: $gilroySemi;
            }
        }
        &:nth-child(3) {
            .main {
                .linkText {
                    font-family: $gilroySemi;
                }
            }
        }
        &:nth-child(4) {
            .main {
                .linkText {
                    font-family: $gilroySemi;
                }
            }
        }
    }
    .accordionSpItem {
        .accordionTarget {
            opacity: 0;
            visibility: hidden;
        }
    }
    .is-active.accordionSpItem {
        .accordionTarget {
            opacity: 1;
            visibility: visible;
        }
    }
    .headerNavLink {
        color: $white;
    }
}
