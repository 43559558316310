body {
    font-family: 'Noto Sans JP', sans-serif;
    // overflow-x: hidden;
}
.device-mobile {
    body {
        font-family: 'Hiragino Kaku Gothic ProN', 'Hiragino Sans', sans-serif;
    }
}

/**
   * @license
   * MyFonts Webfont Build ID 4105980, 2021-06-18T22:24:49-0400
   * 
   * The fonts listed in this notice are subject to the End User License
   * Agreement(s) entered into by the website owner. All other parties are 
   * explicitly restricted from using the Licensed Webfonts(s).
   * 
   * You may obtain a valid license at the URLs below.
   * 
   * Webfont: Gilroy-SemiBold by Radomir Tinkov
   * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/semi-bold/
   * 
   * Webfont: Gilroy-Light by Radomir Tinkov
   * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/light/
   * 
   * Webfont: Gilroy-ExtraBold by Radomir Tinkov
   * URL: https://www.myfonts.com/fonts/radomir-tinkov/gilroy/extra-bold/
   * 
   * 
   * Webfonts copyright: Copyright &amp;#x00A9; 2016 by Radomir Tinkov. All rights reserved.
   * 
   * © 2021 MyFonts Inc
  */

/* @import must be at top of file, otherwise CSS will not work */
@import url('//hello.myfonts.net/count/3ea6fc');

@font-face {
    font-family: 'Gilroy-ExtraBold';
    font-display: swap;
    src: url('webFonts/GilroyExtraBold/font.woff2') format('woff2'),
        url('webFonts/GilroyExtraBold/font.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-Light';
    font-display: swap;
    src: url('webFonts/GilroyLight/font.woff2') format('woff2'),
        url('webFonts/GilroyLight/font.woff') format('woff');
}
@font-face {
    font-family: 'Gilroy-SemiBold';
    font-display: swap;
    src: url('webFonts/GilroySemiBold/font.woff2') format('woff2'),
        url('webFonts/GilroySemiBold/font.woff') format('woff');
}

// // noto_sans
// @font-face {
//   font-family: "Noto Sans JP";
//   font-display: swap;
//   src: url('webFonts/NotoSansJP/font.woff2') format('woff2'), url('webFonts/NotoSansJP/font.woff') format('woff');
// }
